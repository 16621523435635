import styled from 'styled-components';

const menuBarHeight = 60;

interface MenuOpenProps {
  isMenuOpen: boolean;
}

export const NavPaneWrapper = styled.div<MenuOpenProps>`
  background-color: ${props => props.theme.background.navPane};
  min-height: ${menuBarHeight}px;
  height: ${menuBarHeight}px;
  max-height: ${menuBarHeight}px;
  padding: 0px;

  z-index: 100;
  @media screen and (min-width: 768px) {
    display: none;
  }
  
  @media screen and (max-width: 768px) {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`;

export const MenuBar = styled.div`
  background-color: ${props => props.theme.background.navPane};
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: ${menuBarHeight}px;
  position: fixed;
  width: 100%;
`;

export const MenuButton = styled.div`
  color: ${props => props.theme.text.light};
  font-size: 45px;
  position: absolute;
    top: 0px;
    left: 0px;
  text-align: center;
  width: 40px;
`;

export const MenuBox = styled.div<MenuOpenProps>`
  background-color: ${props => props.theme.background.navPane};
  box-shadow: 0px 15px 20px rgba(0,0,0,0.4);
  display: ${props => props.isMenuOpen ? 'flex': 'none'};
  flex-direction: column;
  align-items: left;
  margin-top: ${menuBarHeight}px;
  padding: 20px;
  z-index: 100;
`;

