import styled from 'styled-components'

export const AppWrapper = styled.div`
  color: ${props => props.theme.text.dark};
  display: flex;
  height: 100vh;
  width: 100%;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContentWrapper = styled.div`
  overflow: scroll;

  @media screen and (min-width: 768px) {
    width: calc(100vw - ${props => props.theme.navPane.width}px);
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
