import { useState } from 'react';
import {
  NavPaneWrapper,
  MenuBox,
  MenuBar,
  MenuButton,
} from './NavPaneMobile.styles';
import {
  Title,
  TitleLink,
  ProfileImage,
  MenuItem
} from './NavPaneShared.styles';
import profileImage from 'static/profile-snow.png';


const menuItems = [
  {title: "Articles", href: "/articles/"},
  {title: "Reference", href: "/reference/"},
  {title: "Github", href: "https://github.com/joecortopassi", target: "_blank"},
  {title: "Twitter", href: "https://twitter.com/JoeCortopassi", target: "_blank"},
  {title: "LinkedIn", href: "https://www.linkedin.com/in/joecortopassi/", target: "_blank"},
  {title: "Resume", href: "/static/joe_cortopassi_resume.pdf", target: "_blank"},
];

const NavPaneMobile = () => {
  const [ isMenuOpen, setIsMenuOpen ] = useState(false);

  return (
    <NavPaneWrapper isMenuOpen={isMenuOpen}>
      <MenuBar>
        <MenuButton
          role="button"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          { isMenuOpen ? "\u2715" : "\u2630" }
        </MenuButton>
        <TitleLink href="/">
          <Title>Joe Cortopassi</Title>
        </TitleLink>
      </MenuBar>
      <MenuBox isMenuOpen={isMenuOpen}>
        <ProfileImage src={profileImage} />
        {menuItems.map(({title, href, target="_self"}) => (
          <MenuItem
            key={title}
            href={href}
            target={target}
          >
            {title} {target === "_blank" && "\u2197"}
          </MenuItem>
        ))}
      </MenuBox>
    </NavPaneWrapper>
  );
};

export default NavPaneMobile;


