const theme = {
  background: {
    app: 'transparent',
    navPane: '#002B36'
  },
  text: {
    dark: '#404040',
    darkSubtle: '#808080',
    light: '#eee'
  },
  navPane: {
    width: 300
  }
};

export default theme;
