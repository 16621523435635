import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  computer,
  server
} from 'components/canvas/objects';

const StyledCanvas = styled.canvas`
  border: 3px solid #333;
  border-radius: 15px;
  margin: 30px;
  height: 90%;
  width: 90%;
`;

const createRectangle = (x, y, width, height) => ({x, y, width, height});
const createLine = (x1, y1, x2, y2) => ({x1, y1, x2, y2});

function drawRectangles(ctx, rectangles) {
  const rectBorder = 5;

  // Draw rectangles
  ctx.strokeStyle = 'black';
  ctx.lineWidth = rectBorder;
  rectangles.map(({x, y, width, height}) => { 
    ctx.strokeRect(x, y, width, height);
  });
}

function drawLines(ctx, lines) {
  // Line properties
  const lineColor = 'blue';
  const lineWidth = 3;

  // Draw line
  ctx.strokeStyle = lineColor;
  ctx.lineWidth = lineWidth;
  lines.map(({x1, y1, x2, y2}) => {
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
  });
}

let circlePosition = 0;
function drawCircles(ctx, line) {
  // Circle properties
  const circleRadius = 6; // 6px diameter
  const lineLength = line.x2 - line.x1;
  const spacing = (line.x2 - line.x1)/5;
  const fadeInPixelLength = 20;
  const circles = Array(5).fill(0);

  // Draw moving circle
  ctx.fillStyle = 'green';
  circles.map((_, i) => {
    let alpha = 1;
    ctx.beginPath();
    const offset = spacing * i;
    let position = line.x1 + offset + circlePosition;
    position = position > line.x2 ? position - lineLength : position;

    if ((position - line.x1) < fadeInPixelLength) {
      alpha = (position - line.x1) / fadeInPixelLength;
    } else if ((line.x2 - position) < fadeInPixelLength) {
      alpha = (line.x2 - position) / fadeInPixelLength;
    }
    ctx.globalAlpha = alpha;
    ctx.arc(position, line.y1, circleRadius, 0, Math.PI * 2);
    ctx.fill();
  });
  circlePosition += 1;
  if (circlePosition > spacing) circlePosition = 0;
  ctx.globalAlpha = 1;
}


function drawDatabase(ctx) {
  ctx.save();
  ctx.translate(0, 300);
  // Function to draw a single cylinder (database layer)
  function drawCylinder(x, y, width, height) {
    var ellipseRadius = width / 2;
    
    // Bottom ellipse
    ctx.beginPath();
    ctx.ellipse(x + ellipseRadius, y + height, ellipseRadius, height / 2, 0, 0, 2 * Math.PI);
    ctx.fillStyle = '#999'; // Grey color
    ctx.fill();
   
    // Rectangle for the body (for fill only)
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.fillStyle = '#999'; // Lighter grey
    ctx.fill();

    
    // Top ellipse
    ctx.beginPath();
    ctx.ellipse(x + ellipseRadius, y, ellipseRadius, height / 2, 0, 0, 2 * Math.PI);
    ctx.fillStyle = '#666'; // Grey color
    ctx.fill();
  }

  // Draw multiple cylinders to represent the database
  var x = 50; // X position
  var y = 50; // Starting Y position
  var width = 200; // Cylinder width
  var height = 30; // Cylinder height

  for (var i = 2; i >= 0; i--) {
      drawCylinder(x, y + ((0.8 * height) * i * 1.5), width, height);
  }
  ctx.restore();
}

const CanvasPage = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    const ctx = canvas.getContext('2d');
    let animationFrameId;


    // Drawing function
    const draw = () => {
      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      computer.draw(ctx);
      server.draw(ctx);
      drawDatabase(ctx);

      drawRectangles(ctx, [
        createRectangle(50, 150, 100, 100),
        createRectangle(250, 150, 100, 100)
      ]);
      drawLines(ctx, [
        createLine(100, 200, 300, 200)
      ]);
      drawCircles(ctx, createLine(100, 200, 300, 200));

      // Request next frame
      animationFrameId = requestAnimationFrame(draw);
    };

    // Start animation
    draw();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return <StyledCanvas ref={canvasRef} />;
};

export default CanvasPage;
