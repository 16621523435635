const referenceFiles: {[key: string]: any} = {
  "useful-command-line-snippets": {
    "title": "Useful Command Line Workflows",
    "date": "2018-09-29T10:40:25-07:00",
    "file": "useful-command-line-snippets.md",
    "slug": "useful-command-line-snippets"
  },
  "useful-git-workflows": {
    "title": "Useful Git Workflows",
    "date": "2018-09-29T10:39:28-07:00",
    "file": "useful-git-workflows.md",
    "slug": "useful-git-workflows"
  },
  "useful-python-commands": {
    "title": "Useful Python Commands",
    "date": "2024-04-15T12:00:00-07:00",
    "file": "useful-python-commands.md",
    "slug": "useful-python-commands"
  },
  "useful-tmux-commands": {
    "title": "Useful Tmux Commands",
    "date": "2019-11-28T10:40:25-07:00",
    "file": "useful-tmux-commands.md",
    "slug": "useful-tmux-commands"
  }
}
export default referenceFiles;