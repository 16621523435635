function drawComputer(ctx) {
  ctx.save();
  ctx.translate(40, 40);

  {
    // Draw the monitor
    ctx.lineWidth = 5;
    ctx.fillStyle = '#DDD';
    ctx.strokeStyle = '#000'; // Black color
    ctx.fillRect(10, 0, 100, 60); // x, y, width, height
    ctx.stroke();

    // Draw the base of the monitor
    ctx.fillStyle = '#333'; // Dark grey color
    ctx.fillRect(35, 62, 50, 10); // x, y, width, height

    // Draw the keyboard
    ctx.fillStyle = '#666'; // Light grey color
    ctx.roundRect(0, 67, 120, 14, [5, 5, 0, 0]); // x, y, width, height
    ctx.fill();
  }

  ctx.restore();
}

function drawServer(ctx) {
  ctx.save();

  // Draw the server body
  ctx.fillStyle = '#333'; // Dark grey color for the server
  ctx.fillRect(350, 350, 100, 100); // x, y, width, height

  // Draw server racks/panels
  ctx.fillStyle = '#555'; // Slightly lighter grey for racks
  for (var y = 60; y < 140; y += 20) {
      ctx.fillRect(360, 300+y, 80, 10); // x, y, width, height
  }

  // Draw lights or buttons
  ctx.fillStyle = 'red'; // Color for lights
  for (var y = 65; y < 140; y += 20) {
      ctx.beginPath();
      ctx.arc(370, 300+y, 3, 0, Math.PI * 2, true); // x, y, radius, startAngle, endAngle, anticlockwise
      ctx.fill();
  }

  ctx.restore();
}

export const computer = { draw: drawComputer };
export const server = { draw: drawServer };


