import profileImage from 'static/profile-snow.png';
import {
  NavPaneWrapper,
  MenuBox
} from './NavPane.styles';
import {
  Title,
  TitleLink,
  ProfileImage,
  MenuItem
} from './NavPaneShared.styles';

const menuItems = [
  {title: "Articles", href: "/articles/"},
  {title: "Reference", href: "/reference/"},
  {title: "Github", href: "https://github.com/joecortopassi", target: "_blank"},
  {title: "Twitter", href: "https://twitter.com/JoeCortopassi", target: "_blank"},
  {title: "LinkedIn", href: "https://www.linkedin.com/in/joecortopassi/", target: "_blank"},
  {title: "Resume", href: "/static/resume_joe_cortopassi.pdf", target: "_blank"},
];

const NavPane = () => (
  <NavPaneWrapper>
    <TitleLink href="/">
      <Title>Joe Cortopassi</Title>
    </TitleLink>
    <ProfileImage src={profileImage} />
    <MenuBox>
      {menuItems.map(({title, href, target="_self"}) => (
        <MenuItem
          key={title}
          href={href}
          target={target}
        >
          {title} {target === "_blank" && "\u2197"}
        </MenuItem>
      ))}
    </MenuBox>
  </NavPaneWrapper>
);

export default NavPane;
