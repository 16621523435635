import styled from 'styled-components';

export const Title = styled.h1`
  color: ${props => props.theme.text.light};
  text-align: center;
  @media screen and (min-width: 768px) {
    font-size: 38px;
    margin: 30px 0px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 24px;
    margin: 7px 0px;
    padding: 10px;
  }
`;

export const TitleLink = styled.a`
  color: ${props => props.theme.text.light};
  text-decoration: none;
`;

export const ProfileImage = styled.img`
  background-color: #333;
  border-radius: 5px;
  height: auto;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MenuBox = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: left;
  padding: 20px 0px;
`;

export const MenuItem = styled.a`
  color: ${props => props.theme.text.light};
  font-weight: bold;
  margin-bottom: 10px;
  text-decoration: none;
`;

