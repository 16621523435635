import ReactMarkdown from "react-markdown";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import { solarizedDarkAtom } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { CodeWrapper, InlineCodeWrapper } from './MarkdownArticle.styles';


const MarkdownArticle = ({contentData}) => {
  return (
    <ReactMarkdown
      children={contentData}
      components={{
        code(props) {
          const {children, className, node, ...rest} = props
          const match = /language-(\w+)/.exec(className || '')
          if (props.inline) {
            return (
              <InlineCodeWrapper>
                <code {...rest} className={className}>
                  {children}
                </code>
              </InlineCodeWrapper>
            );
          } else {
            return (
              <CodeWrapper>
                <SyntaxHighlighter
                  {...rest}
                  style={solarizedDarkAtom}
                  children={String(children).replace(/\n$/, '')}
                  language={match ? match[1] : ''}
                  PreTag="div"
                />
              </CodeWrapper>
            );
          }
        }
      }}
    />
  );
};

export default MarkdownArticle;
