const articlesFiles: {[key: string]: any} = {
  "functional-composition-in-javascript": {
    "title": "Functional Composition in Javascript",
    "date": "2018-10-10T16:50:08-05:00",
    "file": "functional-composition-in-javascript.md",
    "slug": "functional-composition-in-javascript"
  },
  "react-context-api": {
    "title": "React Context Api",
    "date": "2018-09-09T14:56:45-05:00",
    "file": "react-context-api.md",
    "slug": "react-context-api"
  },
  "rules-for-remote-work": {
    "title": "My Rules for Remote Work",
    "date": "2019-09-29T14:56:45-05:00",
    "file": "rules-for-remote-work.md",
    "slug": "rules-for-remote-work"
  },
  "tech-term-glossary-wrong-answers-only": {
    "title": "Tech Term Glossary: Wrong Answers Only",
    "date": "2020-06-08T20:32:28-07:00",
    "file": "tech-term-glossary-wrong-answers-only.md",
    "slug": "tech-term-glossary-wrong-answers-only"
  },
  "unpopular-opinions-about-tech": {
    "title": "Unpopular Opinions About Tech",
    "date": "2019-11-16T17:32:28-07:00",
    "file": "unpopular-opinions-about-tech.md",
    "slug": "unpopular-opinions-about-tech"
  }
}
export default articlesFiles;