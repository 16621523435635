import styled from 'styled-components';
import { Link } from "react-router-dom";

export const ContentList = styled.ul`
  margin: 0px;
  padding-left: 0;
  text-transform: capitalize;

  > li {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
  }
`;

export const ArticleLink = styled(Link)`
  color: ${props => props.theme.text.dark};
`;

export const ArticleTitle = styled.h3`
  font-size: 22px;
  margin-bottom: 0;
`;

export const ArticleDate = styled.h4`
  color: ${props => props.theme.text.darkSubtle};
  font-size: 12px;
  font-weight: bold;
  margin-top: 0;
`;

export const ContentPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;

  @media screen and (min-width: 768px) {
    padding: 0% 6% 4% 10%;
  }
  
  @media screen and (max-width: 768px) {
    margin-top: 10px;
    padding: 0% 4% 5% 4%;
  }
`;

export const ContentTitle = styled.h1`
  color: ${props => props.theme.text.dark};
  font-size: 48px;
  margin: 0;
  padding: 30px 0px 0px;
  text-transform: capitalize;
  text-decoration: underline;
`;

export const SubTitle = styled.div`
  color: ${props => props.theme.text.darkSubtle};
  font-size: 12px;
  font-weight: bold;
`;

export const ContentBody = styled.div`
  margin-top: 30px;
  max-width: 600px;
`;
