import { useState } from 'react';
import styled, {ThemeProvider} from 'styled-components'
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  useParams
} from "react-router-dom";
import { AppWrapper, ContentWrapper } from './App.styles';
import { NavPane, NavPaneMobile } from 'components/NavPane';
import ContentPage from 'layouts/ContentPage';
import CanvasPage from 'layouts/CanvasPage';
import theme from "Theme";



const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppWrapper>
        <NavPane />
        <NavPaneMobile />
        <ContentWrapper>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate to="/articles" replace />} />
              <Route path="articles/:slug" element={<ContentPage contentType="articles"/>} />
              <Route path="articles/" element={<ContentPage contentType="articles"/>} />
              <Route path="resume" element={<h2>Resume</h2>} />
              <Route path="about" element={<h2>About</h2>} />
              <Route path="network-diagram" element={<CanvasPage />} />
              <Route path="reference/:slug" element={<ContentPage contentType="reference"/>} />
              <Route path="reference/" element={<ContentPage contentType="reference"/>} />
              <Route path="how-to" element={<h2>How To</h2>} />
              <Route path="projects" element={<h2>Projects</h2>} />
              <Route path="404" element={<h2>404</h2>} />
              <Route
                  path="*"
                  element={<Navigate to="/404" replace />}
              />
            </Routes>
          </BrowserRouter>
        </ContentWrapper>
      </AppWrapper>
    </ThemeProvider>
  );
}

export default App;
