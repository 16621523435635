import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useContentData } from "hooks";
import ContentFiles from "content";
import {
  ContentList,
  ArticleLink,
  ArticleDate,
  ArticleTitle,
  ContentPageWrapper,
  ContentTitle,
  SubTitle,
  ContentBody
} from "./ContentPage.styles";
import MarkdownArticle from "components/MarkdownArticle";


type ContentPageProps = {
  contentType: string;
};

const monthNames = (month: number = 0) => {
  const names = ["January","February","March","April","May","June","July", "August","September","October","November","December"];
  return names[month-1];
}


const getPublishDate = (str: string): string => {
  if (!str) return;
  const date = str.split("T")[0];
  const [year, month, day] = date.split('-').map(str => parseInt(str, 10));
  return `${monthNames(month)} ${day}, ${year}`;
}


const ContentPage = ({contentType}: ContentPageProps) => {
  let { slug = '' } = useParams();
  const navigate = useNavigate();
  
  const [metaData, contentData] = useContentData(contentType, slug);
  let publishDate: string;
  let contentDisplay: React.ReactElement;
  let title: string;
  let linkUrl: string;
  
  if (metaData.error && metaData.error === "invalid slug") {
    navigate(`/${contentType}`, { replace: true });
  }

  if (slug.length > 0 && ContentFiles[contentType][slug]) {
    title = metaData.title;
    publishDate = getPublishDate(metaData.date);
    linkUrl = `/${contentType}/${slug}/`;
    contentDisplay = (
      <MarkdownArticle contentData={contentData} />
    );
  } else {
    title = contentType;
    linkUrl = `/${contentType}/`;
    contentDisplay = (
      <ContentList>
        {Object.keys(ContentFiles[contentType])
            .map(key => ContentFiles[contentType][key])
            .map(obj=> (
              <li key={obj.file}>
                <ArticleLink to={`/${contentType}/${obj.slug}/`}>
                  <ArticleTitle>{obj.title}</ArticleTitle>
                </ArticleLink>
                <ArticleDate>{ getPublishDate(obj.date) }</ArticleDate>
              </li>
            ))
        }
      </ContentList>
    );
  }

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <ContentPageWrapper>
      <a href={linkUrl}>
        <ContentTitle>{title}</ContentTitle>
      </a>
      {publishDate && (
        <SubTitle>{publishDate} by Joe Cortopassi</SubTitle>
      )}
      <ContentBody>
        { contentDisplay }
      </ContentBody>
    </ContentPageWrapper>
  );
}

export default ContentPage;
