import styled from 'styled-components';

export const CodeWrapper = styled.div`
  border-radius: 5px;
  overflow: hidden;
  > div {
    margin: 0px !important;
  }
`;

export const InlineCodeWrapper = styled.span`
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.1);
  padding: 0px 3px;
`;
