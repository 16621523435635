import { useEffect, useState } from 'react';
import ContentFiles from "content";

function formatContentText(text: string) {
  const lines = text.split('\n');
  const metaDataLines = lines.slice(1,4);
  const metaData = metaDataLines
    .map(str => {
      const [key, ...rest] = str.split(':');
      return {[key]: rest.join(':').trim()};
    })
    .reduce((acc, curr) => ({...acc, ...curr}), {});
  const contentText = lines.slice(5).join('\n');

  return {metaData, contentText};
}

function useContentData (contentType: string, slug: string) {
  let [contentData, setContentData] = useState('');
  let [metaData, setMetaData] = useState({});
  useEffect(() => {
    if (slug.length > 0 && !ContentFiles[contentType][slug]) {
      setMetaData({error: "invalid slug"});
    } else if (slug.length === 0) {
      setMetaData({error: "no slug defined"});
    } else {
      const file = ContentFiles[contentType][slug].file;
      const contentData = require(`content/${contentType}/${file}`);
      fetch(contentData)
        .then((data) => data.text())
        .then(text => {
          const { metaData, contentText } = formatContentText(text);
          setMetaData(metaData);
          setContentData(contentText)
        });
    }
  }, [slug]);

  return [metaData, contentData];
};

export default useContentData;
