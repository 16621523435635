import styled from 'styled-components';


export const NavPaneWrapper = styled.div`
  background-color: ${props => props.theme.background.navPane};
  height: 100vh;
  padding: 0px 30px;
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: ${props => props.theme.navPane.width}px;
  }
  
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MenuBox = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: left;
  padding: 20px 0px;
`;

